.testimonials .page-title {
  background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://plus.unsplash.com/premium_photo-1682309504951-43bae484e04d?q=80&w=1934&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
  bottom / cover no-repeat fixed;
  text-align: center;
  height: 500px;
}

.testimonials .card {
  height: 250px;
  border-radius: 0.75rem;
  border-color: var(--earthy-brown);
  box-shadow: 0 0 30px var(--sandy-beige) inset;
}

.testimonials .image-box {
  height: 150px;
}

.testimonials .image-box img {
  height: 150px;
  width: 100%;
  object-fit: cover;
  box-shadow: 0 0 5px var(--earthy-brown) ;
}

.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}
