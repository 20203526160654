/* @tailwind base; */
@tailwind components;
@tailwind utilities;

:root {
  --earthy-brown :#8B4513;
  --sandy-beige:#D2B48C;
  --deep-blue :#003366;
  --warm-gray :#808080;
}
.direction-ltr ,
.image-gallery-thumbnails-wrapper {
  direction: ltr;
}
.App {
  text-align: center;
  font-family: 'Tajawal';
}
body{
  font-family: 'Tajawal' !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .spinner {
    animation: spin infinite 1s linear;
  }
}

button:disabled {
  cursor: progress;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#margin-0 {
  margin-top: 0 !important;
}