.navbar {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    box-shadow: 0 3px 17px var(--warm-gray), 0 0 30px var(--sandy-beige) inset;
    top: 0;
}

.logo {
    width: 50px;
    height: 50px;
}

.link.nav-link {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;
    text-align: center;
}

.nav-link::after {
    content: "";
    width: 0;
    position: absolute;
    height: 3px;
    background-color: var(--sandy-beige);
    left: 50%;
    top: 100%;
    transition: all 0.4s;
}

.nav-link:hover::after,
.nav-link.active:after {
    width: 100%;
    left: 0;
}

/*************** Button Styles For Sticky Navbar *************/

.navbar.navStyle {
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    color: #000;
    position: fixed;
    width: 100%;
    z-index: 99;
}

.link.nav-link {
    color: #000;
    font-size: 1.2rem;
    font-weight: 700;
    text-decoration: none;
    margin: 0 13px;
    transition-duration: 0.1s;
}

.login-btn {
    font-size: 19px;
    font-weight: 700;
    padding: 5px 15px;
    color: #000;
}

.login-btn:hover {
    font-weight: 700;
    padding: 5px 15px;
    border-radius: 10px;
    color: gray;
}

.collapsed.navbar-toggler:focus {
    box-shadow: none;
}

.drop-down {
    box-shadow: 0 0 30px inset var(--sandy-beige);
    width: 200px;
}

.lang-bg {
    background-color: var(--sandy-beige);
    color: #fff;
}