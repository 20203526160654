.ads-side {
    display: block;
    z-index: 10000;
    width: 200px;
    max-width: 30%;
    background-color: #0002;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-size: contain;
}

.ads-text {
    font-size: 20px;
}

@media only screen and (max-width: 767px) {
    .ads-text {
        font-size: 12px;
    }
}