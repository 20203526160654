.form-container {
    height: 100%;
    min-height: 100vh;
}

.register-bg {
    background: url(https://images.unsplash.com/photo-1615380100633-c0bcc60b7686?q=80&w=2026&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)
        center / cover no-repeat;
}

.login-bg {
    background: url("https://images.unsplash.com/photo-1598113972215-96c018fb1a0b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
        center / cover no-repeat;
}

.form-logo {
    filter: drop-shadow(0px -4px 10px var(--earthy-brown));
}

.titles-color {
    color: var(--earthy-brown);
}
