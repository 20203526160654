.btn.btn-outline-primary {
    --bs-btn-color: var(--earthy-brown);
    --bs-btn-border-color: var(--sandy-beige);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--sandy-beige);
    --bs-btn-hover-border-color: var(--sandy-beige);
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--sandy-beige);
    --bs-btn-active-border-color: var(--sandy-beige);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--sandy-beige);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--sandy-beige);
    --bs-gradient: none;
    box-shadow: 0 0 10px var(--sandy-beige) inset;
}