.page-not-found {
    background-image: url("https://images.unsplash.com/photo-1554147090-e1221a04a025?q=80&w=2048&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; 
}
.page-not-found p {
    font-size: 300px;
    color: #000000;
}

.page-not-found img {
    width: 300px;
}

@media only screen and (max-width: 991px) {
    .page-not-found p {
        font-size: 200px;
    }
    
    .page-not-found img {
        width: 200px;
    }
}

@media only screen and (max-width: 767px) {
    .page-not-found p {
        font-size: 150px;
    }
    
    .page-not-found img {
        width: 150px;
    }
}
