.contact-page .page-title {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("https://images.unsplash.com/photo-1694878981888-7a526050b455?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
            bottom/cover no-repeat fixed;
    height: 500px;
}

#subject {
    width: 100%;
}

@media only screen and (max-width: 991px) {
    .contact-page .page-title {
        background-attachment: unset;
    }
}
