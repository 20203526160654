.single-item-container {
    box-shadow: 0 0 200px var(--sandy-beige) inset,
    100px 0 100px var(--sandy-beige),
    -100px 0 100px var(--sandy-beige);
}

button.image-gallery-icon:hover {
    color: var(--earthy-brown) ;
}

button.image-gallery-thumbnail.active,
button.image-gallery-thumbnail:hover,
button.image-gallery-thumbnail:focus {
    border-color: var(--earthy-brown);
}