div.flip-container {
    perspective: 1000px;
    max-width: fit-content;
}

.flipper {
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

.flip-container .flipper {
    width: 350px;
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--earthy-brown);
    border:1px solid var(--sandy-beige);
    box-shadow: 0px 0px 30px inset var(--sandy-beige),
        0px 0px 10px var(--sandy-beige);
    transform: scale(0.9);
    }

.flip-container .flipper svg {
    width: 40px;
    height: 40px;
}

.flip-container:hover .flipper {
    transform: rotateY(180deg);
}

.front,
.back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.front {
    transform: rotateY(0deg);
}

.back {
    transform: rotateY(180deg);
}
