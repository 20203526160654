.ads-bot-container  {
    background-color: #0002;
}

.ads-bot {
    background-color: #0002;
    display: block;
    z-index: 10000000;
    width: 50%;
    max-width: 400px;
    margin: auto;
    height: 100px;
    position: fixed;
    bottom: 0;
    /* left: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background-size: contain;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: overlay;
    background-color: rgba(0, 0, 0, .7);
}
