.feedback-page .page-title {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("/public/rate.jpg")
            bottom/cover no-repeat fixed;
    height: 500px;
}

@media only screen and (max-width: 991px) {
    .feedback-page .page-title {
        background-attachment: unset;
    }
}
