.trainers-page.page-title {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/images/trainers.jpg")
    bottom/cover no-repeat fixed;
    height: 500px;
}

.trainers .card {
    border-radius: 0.75rem;
    border-color: var(--earthy-brown);
    box-shadow: 0 0 30px var(--sandy-beige) inset;
  }