.payment-page .page-title {
    background-image: url("/public/towfiqu-barbhuiya-xkArbdUcUeE-unsplash.jpg");
    background-size: cover !important;
    position: relative;
    height: 400px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
  }
  .payment-page .about-title {
    position: relative;
    display: flex;
    justify-content: center;
    list-style: none;
  }
  .payment-page .title-path::before {
    content: "/";
    color: #fff;
    padding-right: 10px;
  }
  
  .payment-page .title-box h1 {
    color: #fff;
    font-weight: 700;
  }
  .payment-page .title-box .about-title {
    padding-right: 25px;
  }
  .payment-page .title-box .about-title li a {
    color: #fff;
    text-decoration: none;
    padding-right: 10px;
  }
  .payment-page .title-box .title-path {
    color: #ff6d00;
    font-weight: 700;
  }
  
  .auto-container {
    position: relative;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
  }
  
  .payment-page .about-section .row {
    margin: 0 !important;
    width: 100%;
  }
  
  .payment-page .about-section h1 {
    font-size: 35px;
    color: rgb(34, 34, 34);
    font-weight: 700;
    padding: 10px 0 20px;
  }
  .payment-page .about-section .header-paragraph {
    font-size: 15px;
    color: grey;
    text-align: justify;
  }
  .a-section {
    padding: 50px 0;
  }
  .overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: overlay;
    background-color: rgba(39, 39, 39, 0.8);
  }
  .payment-page h1 {
    top: 30%;
    position: absolute;
  }
  
  /************ Form ************/
  
  .about-form{
    background-color: white;
    padding: 0px 60px;
    border-radius: 10px;
  }
  
  .about-form h1{
    color: rgb(77, 1, 77);
    text-align: center;
  }
  
  .about-form button{
    width: 100%;
    height: 50px;
    padding: 10px;
    border: none;
    background-color: #0d6efd;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 992px) {
      .payment-form {
        border-inline-start: 1px solid black;
      }
  }