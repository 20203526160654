.service .page-title {
    background:linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://plus.unsplash.com/premium_photo-1661936495413-875706d59696?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")
        center / cover no-repeat fixed;
    text-align: center;
    height: 500px;
}

.service .about-title {
    position: relative;
    display: flex;
    justify-content: center;
    list-style: none;
}

.service-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: overlay;
    background-color: rgba(39, 39, 39, 0.8);
}

/*************** Services Cards ************/

h1.heading {
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 10px;
}

p.heading.p__color {
    margin-bottom: 0;
    text-align: center;
    line-height: 1.2;
}

.service__box {
    border-radius: 10px;
    width: 100%;
    background-color: #fff;
    border: 2px solid #0d6efd;
    padding: 40px;
    transition: 0.4s ease;
    margin: 30px 0;
}

.service__box:hover {
    transform: translateY(5px);
    background-image: linear-gradient(90deg, #0d6efd, #0d6efd);
    color: #fff;
}

.service__box:hover .icon {
    color: #fff;
}

.service__box:hover p.service__text .p__color {
    color: #fff !important;
}

.icon {
    font-size: 54px;
    font-weight: 400;
    margin-bottom: 23px;
    display: inline-flex;
    color: #0d6efd;
}

p.service__text.p__color {
    margin-bottom: 0;
}

h1.service__text {
    font-size: 19px;
    padding: 10px 0px;
}

@media only screen and (max-width: 1024px) {
    .service__box {
        padding: 32px;
    }
}
