.row .user-image-container {
    width: 200px;
    height: 200px;
    border: 1px solid var(--earthy-brown);
    object-fit: cover;
}

.row .user-image-container img {
    height: 200px;
    object-fit: cover;
}

.more-btn {
    width: 150px;
}

.more-btn::after {
    margin-inline-start: 2rem;
}

.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
    background-color: var(--earthy-brown);
}

.profile-container {
    box-shadow: 0 0 50px inset var(--sandy-beige) , 0 0 200px 50px var(--sandy-beige) ;
}