.swiper-slider img {
    height: 700px;
    width: 100%;
    object-fit: cover;
    background-position: center;
    display: block;
    opacity: 0.9;
}

.swiper-slider {
    height: 700px;
    background: transparent;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: center;
}

.image-content {
    position: absolute;
    width: 100%;
    padding: 100px;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    transition: 0.5s;
    z-index: 2;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* mix-blend-mode: overlay; */
    background-color: rgba(0, 0, 0, 0.7);
}

.image-content h2 {
    font-size: 5em;
    font-weight: 800;
    color: #fff;
    line-height: 1em;
    text-transform: uppercase;
}

.image-content h {
    font-size: 4em;
    font-weight: 700;
    color: #fff;
    line-height: 1em;
    text-transform: uppercase;
}
.image-content p {
    font-size: 2.5em;
    color: #fff;
    margin: 20px 0;
    font-weight: 400;
    max-width: 700px;
}

.image-content a {
    display: inline-block;
    font-size: 1em;
    padding: 10px 30px;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 500;
    margin-top: 10px;
    letter-spacing: 2px;
    transition: 0.2s;
}

.image-content a:hover {
    letter-spacing: 6px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .image-content .banner-title {
        font-size: 3em;
    }
    .image-content .banner-text {
        font-size: 1.1em;
    }
}
