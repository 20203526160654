div.item-container {
    border-radius: 8px;
    background-color: #fff;
    transition: 0.6s;
    cursor: pointer;
    padding-top: 40px;
    max-width: 80%;
    margin: auto;
}

.item-container .inner-box {
    transition: all 0.4s;
    color: var(--earthy-brown);
    box-shadow: 0 0 30px var(--sandy-beige) inset;
}

.item-container .inner-box:hover {
    transform: scale(1.2);
}

.item-container .items-section-content {
    padding: 20px;
}

.price-box::after {
    content: "";
    display: block;
    width: 80%;
    height: 2px;
    background-color: var(--earthy-brown);
    position: absolute;
    bottom: 3px;
    left: 10%;
}

.items-section .slick-slide > div {
    width: 100%;
}