.footer {
    padding-top: 100px;
    background-color: var(--sandy-beige);
    box-shadow: 0 0 20px var(--earthy-brown) inset;
}

.footer-links h4,
.footer-link {
    color: #fff;
    font-weight: bold;
    width: fit-content;
}

.footer-links h4,
.footer-link:hover {
    text-shadow: 0 0 10px var(--earthy-brown);
}

.footer-link::before {
    content: "»";
    margin-right: 5px;
    font-size: 20px;
    padding-left: 10px;
}

.footer-link:hover::before {
    color: var(--earthy-brown);
}

@media only screen and (max-width: 767px) {
    .footer-brand {
        align-items: center;
    }
}