.info-card {
    border: 1px solid var(--sandy-beige);
    box-shadow: 0px 0px 30px inset var(--sandy-beige),
        0px 0px 10px var(--sandy-beige);
    width: 350px;
    height: 350px;
    max-width: 80%;
    margin: auto;
    border-radius: 100% 0% 100% 0% / 51% 55% 45% 49%;
    transition: all 0.4s;
}

.info-card,
.info-card a {
    color: var(--earthy-brown);
}

.info-card:hover {
    border-radius: 93% 7% 91% 9% / 7% 90% 10% 93%;
    transform: scale(1.2);
    border-color: var(--earthy-brown);
}

.info-card svg {
    width: 2rem;
    height: 2rem;
    fill: var(--earthy-brown);
}