.btn.btn-primary {
    --bs-btn-bg: var(--sandy-beige);
    --bs-btn-border-color: var(--sandy-beige);
    --bs-btn-hover-bg: var(--earthy-brown);
    --bs-btn-hover-border-color: var(--earthy-brown);
    --bs-btn-active-bg: var(--earthy-brown);
    --bs-btn-active-border-color: var(--earthy-brown);
    --bs-btn-disabled-bg: var(--sandy-beige);
    --bs-btn-disabled-border-color: var(--sandy-beige);
}

.text-shadow {
    text-shadow: #000 1px 0 3px;
}
