.loader {
    width: fit-content;
    font-size: 48px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 10px var(--earthy-brown);
    position: relative;
}
.loader::before {
    content: "";
    position: absolute;
    left: 34px;
    bottom: 20px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    /* border: 5px solid  var(--earthy-brown); */
    border: 5px solid  var(--sandy-beige);
    border-bottom-color: #fff;
    box-sizing: border-box;
    animation: rotation 0.6s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
