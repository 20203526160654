.fb {
  padding-bottom: 80px;
  overflow: hidden;
}

.swiper-btn-center-lr {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.swiper-container-pointer-events {
  touch-action: pan-y;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.dz-banner-heading .overlay-black-light .bnr-img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.img {
  height: 300px;
  width: 300px;
}

.dz-banner-heading .overlay-black-light:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: linear-gradient(160deg, rgba(0, 0, 0, 0.47), rgba(0, 0, 0, 0));
  width: 100%;
  height: 100%;
}

h4,
.h4 {
  font-size: 1.375rem;
}

.container {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}


.item-list-2 {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin: 10px 0;
  border-bottom: 2px dashed var(--border-color);
  padding-bottom: 20px;
}

.item-list-2 .price {
  margin-right: 15px;
}

.item-list-2 .text-style {
  margin-bottom: 10px;
  display: block;
}

.item-list-2 .price h3,
.item-list-2 .price .h3 {
  margin-bottom: 0;
}

.dz-stepper {
  width: 100px;
}



.item-list-2 .input-group {
  margin-bottom: 0;
}

.dz-stepper .bootstrap-touchspin {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.input-group {
  margin-bottom: 15px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dz-stepper .bootstrap-touchspin .input-group-btn .btn {
  border-radius: 40px;
  width: 40px;
  height: 40px;
  padding: 0;
  text-align: center;
  border: 1px solid #E8EFF3;
  font-size: 1.5rem;
  color: #4f658b;
  background-color: transparent;
}


.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}