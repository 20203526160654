.main-input,
.custom-select > div:first-of-type  {
    border: 1px solid var(--sandy-beige);
    box-shadow: 0px 0px 6px inset var(--sandy-beige);
    font-size: 1.25rem;
}

.main-input:focus{
    border-color: var(--earthy-brown);
    box-shadow: 0px 0px 6px inset var(--earthy-brown);
    outline: 0;
}

.main-input.has-error,
.custom-select.error > div:first-of-type {
    border-color: #f44336;
    box-shadow: 0px 0px 6px inset #f44336;
}

.custom-select.en > div:first-of-type {
    border-radius: 0 500px 500px 0 ;
}

.custom-select.ar > div:first-of-type {
    border-radius: 500px 0 0 500px ;
}

.custom-select > div:first-of-type {
    border-radius: 500px;
}
